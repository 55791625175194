import {styled} from 'styled-components';
import { checked, exit } from '../assets';
import { useEffect, useRef, useState } from 'react';

import emailjs from '@emailjs/browser';
import Policy from './Policy';

const S = {
  Modal : styled.section`
    width : 100vw;
    height : 100vh;

    position : fixed;
    top : 0;
    left : 0;
    background : #00000066;
    z-index : 10;

    display : flex;
    justify-content : center;
    align-items : center;
  `,
  Contact : styled.section`
    width : 1200px;

    @media(max-width : 1024px) {
      width : 90%;
    }
  `,
  Exit_section : styled.section`
    height : 50px;
    text-align : end;
  `,
  Exit_logo : styled.img`
    width : 30px;
    height : 30px;
    cursor : pointer;
  `,
  Content : styled.form`
    background : #ffffff;
    border-radius : 10px;

    width : 100%;
    height : calc(100% - 50px);
    max-height : calc(100vh - 200px);

    padding : 60px;
    box-sizing : border-box;

    @media(max-width : 1024px) {
      padding : 60px 40px;
    }

    &.policy {
      padding : 0;
      height : calc(100vh - 200px);
    }
  `,

  Title : styled.h3`
    font-size : 40px;
    font-weight : 800;
    color : #4788c8;
    line-height: 40px;
    text-align : start;
  `,
  Input : styled.input`
    font-size : 18px;
    line-height : 18px;
    width : 45%;
    border : 0;
    border-bottom : 1px solid #dddddd;
    height : 40px;
    
    &:focus {
      outline : none;
      border-bottom : 1px solid #4788c8;
    }

    @media(max-width : 1024px) {
      width : 100%;
    }
  `,
  Top : styled.div`
    display : flex;
    justify-content : space-between;

    @media(max-width : 1024px) {
      flex-direction : column;
    }
  `,
  Input_mess : styled.input`
    font-size : 18px;
    line-height : 18px;
    width : 100%;
    height : 70px;
    border : 0;
    border-bottom : 1px solid #dddddd;
    
    &:focus {
      outline : none;
      border-bottom : 1px solid #4788c8;
    }
  `,
  Input_label : styled.div`
    font-size : 18px;
    font-weight : 500;
    color : #999999;
    text-align : start;

    margin-top : 40px;
  `,
  Check_section : styled.section`
    text-align : start;

    display : flex;
    align-items : center;
    margin-top : 30px;
  `,
  Checkbox : styled.input`
    appearance : none;
    width : 30px;
    height : 30px;
    border-radius : 50%;
    border : 1px solid #999;
    cursor : pointer;

    &:checked {
      background: no-repeat center/cover url(${checked});
      border: none;
    }

    @media(max-width : 1024px) {
      width : 20px;
      height : 20px;
    }
  `,
  Checkbox_label : styled.div`
    font-size: 18px;
    font-weight : 500;
    line-height : 30px;
    margin-left : 10px;
    color : #777777;

    & span {
      color : #4788c8;
      font-weight : 700;
      margin-left : 10px;
      cursor : pointer;
    }
  `,
  Submit : styled.button.attrs(props => ({type : 'submit'}))`
    width : 200px;
    height : 60px;
    background : #bbbbbb;
    border-radius : 50px;
    font-size : 24px;
    font-weight : 600;
    color : #ffffff;
    line-height: 20px;
    letter-spacing : -0.05em;
    border : 0;
    margin-top : 50px;
    
    &.ready{
      cursor : pointer;
      background : #4788c8;
    }
  `
}

const Contact = ({closeModal}) => {

  const close = () => {
    closeModal();
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [ischeck, setIscheck] = useState(false);
  const [cansubmit, setCansubmit] = useState(false);
  const [policy, setPolicy] = useState(false);
  
  useEffect(() => {
    if (name !== "" && email !=="" && msg !=="" && ischeck) {
      setCansubmit(true)
    } else {
      setCansubmit(false)
    }
  }, [name, email, msg, ischeck])

  const submit = (e) => {
    if (name !== "" && email !=="" && msg !=="" && ischeck) {
      postGetintouch(e)
    }
  }

  const formRef = useRef();

  function postGetintouch(e) {
    e.preventDefault();
    // *******key값 변경!!!********
    emailjs.sendForm("service_soinfj4", "template_uwzv92f", formRef.current, "19LP0E-gjU_jO_c75" ).then(
      result => {
        alert("성공적으로 전송하였습니다.");
        formRef.current.reset();
        close()
      },
      error => {
        console.log(error.text);
        alert("전송이 실패되었습니다.")
      }
    )

  };

  return (
    <S.Modal>
      <S.Contact>
        <S.Exit_section>
          <S.Exit_logo src={exit} onClick={() => close()} style={{'fill' : '#ffffff'}} alt='exit'/>
        </S.Exit_section>
        {policy ?
          <S.Content className='policy'>
            <Policy/>
          </S.Content>
          :
          <S.Content ref={formRef} onSubmit={submit}>
            <S.Title>Contact Us</S.Title>

            <S.Top>
              <S.Input placeholder='Your Name *' value={name} name="from_name" onChange={(e) => setName(e.target.value)}/>
              <S.Input placeholder='E-Mail *' value={email} name="email" onChange={(e) => setEmail(e.target.value)}/>
            </S.Top>
            <S.Input_label>Message *</S.Input_label>
            <S.Input_mess value={msg} name="message" onChange={(e) => setMsg(e.target.value)}/>
            <S.Check_section>
              <S.Checkbox type='checkbox' onClick={(e) => setIscheck(e.target.checked)}/>
              <S.Checkbox_label>Collection and use of personal information<span onClick={() => setPolicy(true)}> Privacy Policy</span></S.Checkbox_label>
            </S.Check_section>
            <S.Submit className={cansubmit && 'ready'}>Submit</S.Submit>
          </S.Content>
        }
      </S.Contact>
    </S.Modal>
  )
}

export default Contact;