import {styled} from 'styled-components';
import { Main_gif } from '../assets';
import { useEffect, lazy } from 'react';

const S = {
  Home : styled.section`
    width : 100vw;
    box-sizing : border-box;
  `,
  Title : styled.h1`
    font-size : 80px;
    font-weight : 800;
    line-height : 90px;
    color : #111111;

    @media(max-width : 1024px) {
      font-size : 35px;
      line-height : 40px;
      margin : 40px 0;
    }
  `,
  GIF : styled.img`
    width : calc(100vw - 520px);
    box-sizing : border-box;
    margin : 0 260px 147px;

    @media(max-width : 1024px) {
      width : calc(100vw - 40px);
      height : auto;
      margin : 40px 0 0; 
    }
  `,

}

const Tech = lazy(() => import('./Home/Tech'));
const GPU = lazy(() => import('./Home/GPU'));
const Jikji = lazy(() => import('./Home/Jikji'));

const Home = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  return (
    <S.Home>
      <S.Title>Accelerate Your<br/>AI  Solutions</S.Title>
      <S.GIF src={Main_gif} alt='main intro'/>
      <Tech/>
      <GPU/>
      <Jikji/>
    </S.Home>
  )
}

export default Home;