import './App.css';
import './Font.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import { Home, Tech, Product } from './pages'
import {Suspense, lazy} from 'react';
// import Footer from './components/Footer';

function App() {
  const Footer = lazy(() => import('./components/Footer'))
  return (
    <div className="App">
      <Suspense fallback={<div>loading...</div>}>
        <Router>
          <Nav/>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/tech' element={<Tech/>}/>
            <Route path='/product' element={<Product/>}/>
          </Routes>
          <Footer/>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
